import React, { useEffect } from 'react';
import './App.scss';
import Introduction from './Components/Introduction';
import Logo from './Components/Logo';
import PageSection from './Components/PageSection';
import { BackgroundColor } from './Components/PageSection/PageSection';

function App() {
  useEffect(() => {
    const introduction = document.getElementById('introduction');
    setTimeout(() => {
      introduction?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }, 1000);
  }, []);
  return (
    <>
      <div className="App">
        <Logo />
      </div>
      <Introduction />
    </>
  );
}

export default App;
