import clsx from 'clsx';
import React from 'react';
import { WithChildren } from '../../Types';
import './PageSection.scss';

export enum BackgroundColor {
  White = 'white',
  Mint = 'mint',
}
type PageSectionProps = WithChildren<{
  id: string;
  backgroundColor?: BackgroundColor;
  className?: string;
}>;
const PageSection = ({
  id,
  children,
  backgroundColor = BackgroundColor.White,
  className,
}: PageSectionProps) => {
  return (
    <section
      id={id}
      className={clsx(
        'page-section',
        { mint: backgroundColor === BackgroundColor.Mint },
        { white: backgroundColor === BackgroundColor.White },
        `${className}`
      )}
    >
      {children}
    </section>
  );
};

export default PageSection;
