import './Introduction.scss';
import React from 'react';
import PageSection from '../PageSection';
import Cuties from './assets/bulli.png';
const Introduction = () => {
  return (
    <PageSection id="introduction" className="introduction">
      <div className="text">
        <div>
          <h2>Hallo! 👋🏻</h2>
          <p>
            Wir sind André und Sarah und wir suchen unser erstes gemeinsames
            Zuhause. Der Kölner Wohnunsmarkt ist einfach schlimm und bisher
            haben wir mit unseren bisherigen Versuchen eine Wohnung zu finden
            leider keinen Erfolg gehabt. Deswegen versuchen wir nun so
            aufzufallen und zu erklären, wieso wir die perfekten (Nach-) Mieter
            sind und wir defintiv zur nächsten Besichtigung eingeladen werden
            sollten.
          </p>
        </div>
      </div>
      <img id="bulli" src={Cuties} />
    </PageSection>
  );
};

export default Introduction;
